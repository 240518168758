import React from "react"
import "../global.css"
import Highlights from "../subpages/Highlights"
import Insurance from "../subpages/Insurance"
import End from "../subpages/End";
import AppartementMenu from "../components/AppartementMenu";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PreisBox from "../components/PreisBox"
import SeoHelmet from "../components/seo"

function Ferienwohnung2() {
  const { allFile } = useStaticQuery(graphql`
    query App2Photos {
      allFile(filter: {relativeDirectory: {eq: "appartements/2"}}) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const galleryImages = allFile.edges.map(img => ({
    thumbnail: img.node.childImageSharp.fluid.src,
    renderItem: () => (
      <Img
        fluid={{
          ...img.node.childImageSharp.fluid,
        }}
        imgStyle={{
          objectFit: "contain",
        }}
        alt={"Ferienwohnung 2"}
      />
    ),
  }))

  return (
    <div>
      <SeoHelmet 
        title="Ferienwohnung mit 39 m² für 2 Personen - Appartement in Werfenweng - Haus Gruber Ferienwohnungen"
        description="Das Haus Gruber Ferienwohnungen bietet Ihnen geräumige und gemütlich eingerichtete Appartements. Wir freuen uns auf Ihren Besuch!"
      />
      <Header height={60}/>
      <AppartementMenu active={2} />
      <div className="container mb-3">
        <div className="flexbox">
          <h1 className="text-center my-5">Ferienwohnung 2<br/><small>39 m² für 2 Personen</small></h1>
          <div className="row no-gutters position-relative">
            <div className="col-md-12 mb-md-0">
            <h5>Beschreibung</h5>
              <p>Die Wohnung verfügt über ein Schlafzimmer, eine großzügige Wohnküche, Bad und WC getrennt und einen Balkon. In der Wohnküche auf der Couch bietet sich Platz für weitere zwei Kinder oder einen Erwachsenen. Dusche und WC sind getrennt.</p>
              <h5>Ausstattung</h5>
              <p>TV, kostenlose WLAN-Benutzung. Die Küche ist mit vier Ceran-Herdplatten (kein Backrohr), Mikrowelle, Wasserkocher, Toaster, Filterkaffeemaschine und sämtlichem Koch- und Tafelgeschirr ausgestattet. Bettwäsche, Hand- und Duschtücher, ein Haartrockner sowie Seife und Shampoo sind ebenfalls vorhanden.</p>
            </div>
          </div>
          <div className="row no-gutters position-relative mt-2">
            <div className="col-md-6 mb-md-0 pr-4">
              <h5>Preise</h5>
              <div className="d-flex justify-content-between flex-wrap">
                <div className="p-2 flex-fill">
                  <PreisBox 
                    title={"Sommersaison"}
                    price={74}
                    cleaning={45}
                    color={'rgb(255, 205, 108)'}
                    options={[
                      'Frühling: € 74,-<br><small>15.04.23 - 23.06.23</small>',
                      'Sommer: € 80,-<br><small>24.06.23 - 08.09.23</small>',
                      'Herbst: € 74,-<br><small>09.09.23 - 27.10.23</small>',
                    ]}
                  />
                </div>
                <div className="p-2 flex-fill">
                  <PreisBox
                    title={"Wintersaison"}
                    price={75}
                    cleaning={45}
                    color={'rgb(107, 166, 255)'}
                    options={[
                      'Weihnachten: € 82,-<br><small>17.12.22 - 06.01.23</small>',
                      'Zwischensaison: € 75,-<br><small>07.01.23 - 27.01.23</small>',
                      'Energiewochen: € 82,-<br><small>28.01.23 - 03.03.23</small>',
                      'Nachsaison: € 75,-<br><small>04.03.23 - 14.04.23</small>',
                    ]}
                  />
                </div>
              </div>
              <p><small>Preise pro Nacht und Appartement in Euro. Die Preise beinhalten alle Nebengebüren wie Strom, Wasser, Wäsche exklusive der Ortstaxe von EUR 2,- pro Person Tag für Gäste ab dem 15. Lebensjahr</small></p>
            </div>
            <div className="col-md-6 position-static p-2 pl-md-0">
              <h5>Fotos</h5>
              <ImageGallery
                items={galleryImages}
                thumbnailPosition="bottom"
                showNav={true}
                showFullscreenButton={false}
                infinite={false}
                showPlayButton={false}
                lazyLoad={true}
                showIndex={true}
              />
            </div>
          </div>
        </div>
        </div>
      <Highlights />
      <Insurance />
      <End/>
      <Footer/>
    </div>
  )
}

export default Ferienwohnung2
